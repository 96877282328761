import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router'

const WrongRead = () => {
  const navigate = useNavigate()

  useEffect( () => {
    setTimeout( () => navigate('/QRScan'), 2000)
  }, [])

  return (
    <Container>
        <div className='row'>
            <div className='col-lg-8 mx-auto col-12 d-flex justify-content-center align-items-center'>
                <p className='display-5'>OOPS Card does not exit! Plz Read Again</p>
            </div>
        </div>
    </Container>
  )
}

export default WrongRead