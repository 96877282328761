import { configureStore } from "@reduxjs/toolkit";
import deliveryManReducer from "./deliveryMans/DeliveryManSlice";
import adminAuthReducer from "./adminAuth/adminAuthSlice";
import hotelAuthReducer from "./hotelAuth/hotelAuthSlice";


export const store = configureStore({
    reducer: {
        deliveryMans: deliveryManReducer,
        admin: adminAuthReducer,
        hotel: hotelAuthReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})