import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuth,signOut } from "firebase/auth";
// export const fetchAdminAuth = createAsyncThunk('adminAuth/fetchAdminAuth', async()=>{

// })

const initialState = {
    hotel:null,
}

const hotelAuthSlice = createSlice({
    name:"hotels",
    initialState,
    reducers:{
        setHotelAuth:(state,action)=>{
            // console.log("action", action.payload)
            
            state.hotel = action.payload 
            localStorage.setItem('user', JSON.stringify(action.payload))
        },

        setHotelAuthLogout: state=>{
            state.hotel = null;
            const auth = getAuth();
            signOut(auth).then(()=>{
                localStorage.removeItem('user')
            })
        }
    },
})

export const {setHotelAuth, setHotelAuthLogout} = hotelAuthSlice.actions;
export const getHotelAuth = (state) => state.hotel;
export default hotelAuthSlice.reducer;