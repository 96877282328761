import React from 'react'
import sonixStyle from './SonixDelivery.module.scss'
import sonixImage from '../../assets/photos/sonix-logo.jpeg'
import QR from '../../assets/photos/qr.png'
import Email from '../../assets/photos/email.png'
import Phone from '../../assets/photos/phone-call.png'
import Facebook from '../../assets/photos/facebook.png'

import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

const SonixDelivery = () => {
  return (
    <Container >
      <Row className={sonixStyle.box}>
        <Col lg='8' md='10' className="mx-auto d-lg-flex justify-content-center align-items-center">
          <img src={sonixImage} width='300' alt="" />
          <div className='d-flex flex-column fw-bold fs-4'>
            <span><img src={Email}  className='me-2' width='20'/>sonixdelivery@gmail.com</span>
            <a href="tel:+959454409884"><img src={Phone} className='me-2 my-3' width='20'/>09-454409884</a>
            <a href="tel:+959784337814"><img src={Phone}  className='me-2' width='20'/>09-784337814</a>
            <a href="https://www.facebook.com/sonix.delivery"><img src={Facebook} className='me-2 my-3' width='20'/> Sonix Delivery</a>
          </div>
        </Col>
        <Col lg='8' md='10' className="mt-5 mx-auto d-flex justify-content-center align-items-center">
          <Link className={sonixStyle.btn_sonix + ' btn btn-secondary'} replace to='/QRScan'><img src={QR} className='me-2' width='20' />Check Your Card Here</Link>
        </Col>
      </Row>
    </Container>
  )
}

export default SonixDelivery