import {signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { Container, Row, Col, Alert, Button, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../config/firebaseinit'
import {setAdmin} from "../../features/adminAuth/adminAuthSlice"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../assets/loading.gif';
const AdminLogin = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const adminData = useSelector(state => state.adminData);
  const [notiMessage, setNotiMessage] = useState('');
  console.log('before', adminData);

  const dispatch = useDispatch();

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredentials) => {
        if (userCredentials) {
          const user = await userCredentials.user;
          console.log(user)

          dispatch(setAdmin(user))
          setNotiMessage('Login Success')
          toast.success("Successfully Logged in")
          console.log('admin data', user);
          
          navigate('/admin');
        }

      })
      .catch(err => {
        setLoading(false)
        toast.error(err.message)
        setError(err.message)
      }
      )
  }

  return (
    <Container>
      <Row>
        <Col className='col-5 mx-auto mt-5'>
          <ToastContainer />
          <Card>
            {
              loading ?
                  <Card.Body className="d-flex justify-content-center align-item-center">
                    <img src={Loading} width={100} height={100}/>
                  </Card.Body> :
                  <Card.Body>
                    <h1>Log in</h1>
                    {error && <Alert className='warning'>{error}</Alert>}
                    <Form onSubmit={login} name='login_form'>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" value={email} required onChange={e => setEmail(e.target.value)} placeholder="example@email.com" />
                        <Form.Text className="text-muted">
                          We will never share your addres. Trust us!
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" value={password} required onChange={e => setPassword(e.target.value)} placeholder="enter password" />
                      </Form.Group>
                      <Button className='mt-4' type='submit'>Login</Button>
                    </Form>
                </Card.Body>
            }
          </Card>
        </Col>
      </Row>


    </Container>
  )
}

export default AdminLogin