// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
// import { getAuth} from 'firebase/auth'
import {
  getFirestore,
} from "firebase/firestore";

import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCSQKKcek4oycRpXkgEAA1ZY4GWn-e0Feg",
  authDomain: "flashtourmyanmar.firebaseapp.com",
  databaseURL: "https://flashtourmyanmar.firebaseio.com",
  projectId: "flashtourmyanmar",
  storageBucket: "flashtourmyanmar.appspot.com",
  messagingSenderId: "388611318526",
  appId: "1:388611318526:web:2e923857a8b4e30ad0bbaf",
  measurementId: "G-1YM6233ZGQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
//connectAuthEmulator(auth, 'localhost', 9099);

const analytics = getAnalytics(app);
const db = getFirestore(app);
// connectFirestoreEmulator(db, 'localhost', 7070);

const functions = getFunctions(app);
// connectFunctionsEmulator(functions, 'localhost', 5001);

export { auth, db, functions }
