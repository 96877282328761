import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
  setDoc
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Col, Container, Form, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import { auth, db } from "../../../config/firebaseinit";
import "./hotelDetail.scss";
import { signInWithEmailAndPassword } from 'firebase/auth'
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FaStar } from "react-icons/fa";

const colors = {
  orange: "#027afb",
  grey: "#a9a9a9",
};

function HotelDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState('');
  const [email, setEmail] = useState('');
  const [userList, setUserList] = useState([]);
  const [filterUser, setFilterUser] = useState("");
  const [userId, setUserId] = useState('');
  const [hotel, setHotel] = useState("");
  const { city, hotel_id } = useParams();
  const [facility, setFacility] = useState("");
  const [index, setIndex] = useState(0);
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    const data = localStorage.getItem('user')
    setAuthUser(JSON.parse(data))
  }, [])

  // console.log("auth", authUser?.email)

  const getHotel = async () => {
    setLoadingActive(true)
    const hotelRef = doc(db, city, "Hotel", "HotelDetail", hotel_id);
    const detailRef = collection(
      db,
      city,
      "Hotel",
      "hotel_about",
      "data",
      hotel_id
    );
    try {
      let result = (await getDoc(hotelRef)).data();
      await onSnapshot(detailRef, (snapshot) => {
        setFacility(snapshot.docs[0].data());
      });
      setHotel(result);
      setLoadingActive(false)
    } catch (err) {
      setLoadingActive(false)
      console.log(err.message);
    }
  };

  const getUserList = async () => {
    setUserList([]);
    const hotelRef = collection(db, "User")

    await onSnapshot(hotelRef, (snapshot) => {
      const result = [];
      snapshot.forEach((doc) => {
        result.push(doc.data());
      });
      setUserList(result);
    });
  };


  useEffect(() => {
    getHotel();
    getUserList();
  }, []);

  const login = async (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredentials) => {
        if (userCredentials) {
          const user = await userCredentials.user;
          // console.log(user)
          setUserId(user?.uid)
          // dispatch(setAdmin(user))
          // setNotiMessage('Login Success')
          // toast.success("Successfully Logged in")
          handleClose()
          // navigate('/admin');
        } else {
          console.log("error")
        }

      })
      .catch(err => {
        // setLoading(false)
        toast.error(err.message)
        setError(err.message)
      }
      )
  }

  // useEffect(() => {
  //   const filterUser = userList.filter((user) => user?.id === userId)
  //   // setFilterUser(filterUser)
  // }, [userId])


  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleBook = () => {
    navigate(`/hotel/${city}/${hotel_id}/booking`)
  }

  const handleShow = async () => {
    if (authUser) {
      const q = query(collection(db, "User"), where("email", "==", authUser?.email));
      const snap = await getDocs(q);
      if (snap.docs.length == 0) {
        // setShow(true)
        navigate(`/hotel-login`)
      } else {
        navigate(`/hotel/${city}/${hotel_id}/review`)
      }
    } else {
      navigate(`/hotel-login`)
    }
  }


  // const navigate = useNavigate();
  // const { city, hotel_id } = useParams();
  // const [authUser, setAuthUser] = useState('');
  const [loadingActive, setLoadingActive] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);
  const [comment, setComment] = useState("");
  const [reviews, setReviews] = useState([]);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [dateTime, setDateTime] = useState('');

  const stars = Array(5).fill(0);
  useEffect(() => {
    getReviews();
    getDateTime()
  }, []);

  // useEffect(() => {
  //   const data = localStorage.getItem('user')
  //   setAuthUser(JSON.parse(data))
  // }, [])


  const getReviews = () => {
    const reviewRef = collection(
      db,
      "Review",
      city,
      "Hotels",
      hotel_id,
      "data"
    );
    onSnapshot(reviewRef, (snapshot) => {
      let results = [];
      snapshot.forEach((doc) => {
        results.push(doc.data());
      });
      setReviews(results);
    });
  };

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const getDateTime = () => {
    const date = new Date();
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    let time = new Intl.DateTimeFormat('en-US',
      {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric'
      }).format(date);
    let payload = day + "-" + month + "-" + year + " " + time

    setDateTime(payload);
  }

  const submitReview = async (e) => {
    e.preventDefault();
    setLoadingActive(true);
    // console.log(currentValue, comment);
    if (authUser) {
      const q = query(collection(db, "User"), where("email", "==", authUser?.email));
      const snap = await getDocs(q);
      if (snap.docs.length == 0) {
        // setShow(true)
        navigate(`/hotel-login`)
      } else {
        // navigate(`/hotel/${city}/${hotel_id}/review`)
        let reviewRef = doc(collection(db, 'reviews', city, 'hotels', hotel_id, 'data'));
        console.log("submit")
        try {
          setDoc(reviewRef, {
            'userid': reviewRef.id,
            'name': authUser?.displayName,
            'rating': currentValue,
            'review': comment,
            'date': dateTime
          })
          setLoadingActive(false);
          toast.success('Shop Created Successfully');
          // navigate('/hotel')
        } catch (err) {
          setLoadingActive(false);
          console.log(err.message)
          toast.error('Try again! Cannot create the shop with ', err.message);
        }
      }
    } else {
      navigate(`/hotel-login`)
    }
  };

  // console.log("reviews", reviews)
  // console.log("hotel", hotel)

  return (
    <Container>

      {
        loadingActive ?
          <div className="text-center my-1 fw-bold">
            <span colSpan={8}>Loading ...</span>
          </div>
          :
          <>
            <Row>
              <Col
                xs={12}
                lg={12}
                className="mx-auto my-3">
                <h3 className="text-center">{hotel.name}</h3>
                <span>
                  {city} / {hotel.township}
                </span>
              </Col>
              <Col
                xs={12}
                md={7}>
                <Carousel
                  className="mb-5"
                  activeIndex={index}
                  onSelect={handleSelect}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={hotel.photo1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={hotel.photo2}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={hotel.photo3}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={
                        hotel.photo4
                          ? hotel.photo4
                          : require("../../../assets/photos/banner1.png")
                      }
                      alt="First slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </Col>
              <Col
                xs={12}
                md={5}>
                {facility ? (
                  <Card>
                    <Card.Text className="fs-5">Facilities</Card.Text>
                    <div className="d-flex">
                      {facility.parking ? (
                        <div className="icon-badge">
                          <i className="fa fa-taxi "></i>
                        </div>
                      ) : (
                        <></>
                      )}
                      {facility.wifi ? (
                        <div className="icon-badge">
                          <i className="fa fa-wifi "></i>
                        </div>
                      ) : (
                        <></>
                      )}

                      {facility.breakfast ? (
                        <div className="icon-badge">
                          <i className="fa fa-utensils "></i>
                        </div>
                      ) : (
                        <></>
                      )}
                      {facility.pool ? (
                        <div className="icon-badge">
                          <i className="fa fa-swimming-pool "></i>
                        </div>
                      ) : (
                        <></>
                      )}
                      {facility.ticketservice ? (
                        <div className="icon-badge">
                          <i className="fa fa-tags "></i>
                        </div>
                      ) : (
                        <></>
                      )}
                      {facility.fit ? (
                        <div className="icon-badge">
                          <img
                            src={require("../../../assets/exercise.png")}
                            width="30"
                            height="30"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {facility.wifi ? (
                        <div className="icon-badge">
                          <img
                            src={require("../../../assets/bar-counter.png")}
                            width="30"
                            height="30"
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Card>
                ) : (
                  <></>
                )}
                <Button
                  variant="primary"
                  className="w-100 rounded-pill my-3"
                  onClick={handleBook}
                >
                  Book Now
                </Button>

                <Card>
                  <Card.Text className="fs-5">Contact</Card.Text>
                  <table className="table border-none">
                    <tbody>
                      <tr>
                        <td>Phone</td>
                        <td>{hotel.phone}</td>
                      </tr>
                      <tr>
                        <td>Address</td>
                        <td>{hotel.location}</td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
                <Card>
                  <Card.Text className="fs-5">Room Price Range</Card.Text>
                  <div className="d-flex align-items-center">
                    <i className="fa fa-comment-dollar fa-2x text-primary"></i>
                    <span className="ms-2 ">{hotel.price}</span>
                  </div>
                </Card>

                <div className="floating-btn rounded-circle shadow-lg">
                  <Button
                    variant="success"
                    className="shadow-lg d-flex text-center justify-content-center align-items-center">
                    <i className="fa fa-directions fa-2x"></i>
                  </Button>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} lg={6} className="mx-auto text-center">
                <h3>Review</h3>
                <div className="w-50 mx-auto">
                  <Button
                    variant="primary"
                    className="w-100 rounded-pill mt-3 mb-5"
                  // onClick={() => setModalShow(true)}
                  // onClick={handleShow}
                  >
                    Tap to give a review
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col
                lg={4}
                className="mx-auto">
                <Row style={{ height: "40vh", overflowY: "scroll" }}>
                  {reviews ? (
                    reviews.map((result) => (
                      <div className="comment" key={result?.userid}>
                        <div className="d-flex align-items-center">
                          <img
                            src={result.profile}
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                            }}
                            className="rounded-circle"
                          />
                          <div className="ms-2 d-flex flex-column">
                            <span className="fw-bold">{result.name}</span>
                            <span className="">{result.rating}/5.0</span>
                          </div>

                        </div>
                        <p className="mt-1 ps-5">{result.review}</p>


                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </Row>
                <div className="my-2 d-flex justify-content-center">
                  {stars.map((_, index) => {
                    return (
                      <FaStar
                        key={index}
                        size={24}
                        onClick={() => handleClick(index + 1)}
                        onMouseOver={() => handleMouseOver(index + 1)}
                        onMouseLeave={handleMouseLeave}
                        color={
                          (hoverValue || currentValue) > index
                            ? colors.orange
                            : colors.grey
                        }
                        style={{
                          marginRight: 10,
                          cursor: "pointer",
                        }}
                      />
                    );
                  })}
                </div>
                <Form onSubmit={submitReview}>
                  <Form.Control
                    as="textarea"
                    required
                    cols={20}
                    rows={5}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Comment ..."
                  />

                  <Button
                    className="my-2 float-end"
                    type="submit"
                    variant="primary">
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </>
      }

      <Modal
        // {...props}
        show={show} onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Please Login To Review
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Email </Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                autoFocus
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={login}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default HotelDetail;
