import React, {useState} from "react";
import QrReader from "react-qr-scanner";

import { Container } from 'react-bootstrap'
import { useNavigate } from "react-router";

const QRScan = () => {
  const [result,setResult] = useState(null)
  const navigate = useNavigate()

  const handleScan = data => {
    if (data && data.length === 20) {
      setResult(data)
      navigate(`/cardDetail/${data}`)
    }else if(data){
      navigate('/wrongread')
    }
  }
  const handleError = err => {
    console.error(err)
  }

  return (
    <Container>
        <div className="row">
          <div className="col-8 mx-auto">
          <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
          />
         </div>
        </div>
       <p>{result}</p>
    </Container>
  )
}

export default QRScan;
