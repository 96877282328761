import { signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { Container, Row, Col, Alert, Button, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../config/firebaseinit'
import { setHotelAuth } from "../../../features/hotelAuth/hotelAuthSlice"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../../assets/loading.gif';
// import auth from '../../../config/firebaseinit' 
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { db } from "../../../config/firebaseinit"
import {
  query,
  getDocs,
  collection,
  where,
  doc,
  setDoc
} from "firebase/firestore";

const Login = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const adminData = useSelector(state => state.adminData);
  const [notiMessage, setNotiMessage] = useState('');
  // console.log('before', adminData);

  const dispatch = useDispatch();

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredentials) => {
        if (userCredentials) {
          const user = await userCredentials.user;
          // console.log("dd", user)
          dispatch(setHotelAuth(user))
          setNotiMessage('Login Success')
          toast.success("Successfully Logged in")
          // console.log('admin data', user);
          navigate('/hotel');
        }

      })
      .catch(err => {
        setLoading(false)
        toast.error(err.message)
        setError(err.message)
      }
      )
  }

  const submitGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const res = await signInWithPopup(auth, provider)
    const user = res.user;
    const q = query(collection(db, "User"), where("email", "==", user.email));
    const snap = await getDocs(q);
    if (snap.docs.length == 0) {
      let userRef = doc(collection(db, 'User'));
      try {
        setDoc(userRef, {
          'id': userRef.id,
          'email': user.email,
          'name': user.displayName,
          'photo': user.photoURL
        })
        dispatch(setHotelAuth({ email: user.email, id: user.uid, name: user.displayName, photo: user?.photoURL }))
        navigate('/hotel');
      } catch (err) {
        console.log(err.message)
      }
    } else {
      dispatch(setHotelAuth(snap.docs[0].data()))
      navigate('/hotel');
    }
  }

  const SignInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    const res = await signInWithPopup(auth, provider)
    const user = res.user;    
    const q = query(collection(db, "User"), where("email", "==", user.email));
    const snap = await getDocs(q);
    if (snap.docs.length == 0) {
      let userRef = doc(collection(db, 'User'));
      try {
        setDoc(userRef, {
          'id': userRef.id,
          'email': user.email,
          'name': user.displayName,
          'photo': user.photoURL
        })
        dispatch(setHotelAuth({ email: user.email, id: user.uid, name: user.displayName, photo: user?.photoURL }))
        navigate('/hotel');
      } catch (err) {
        console.log(err.message)
      }
    } else {
      dispatch(setHotelAuth(snap.docs[0].data()))
      navigate('/hotel');
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    navigate('/hotel-singup')
  }

  return (
    <Container>
      <Row>
        <Col className='col-5 mx-auto mt-5'>
          <ToastContainer />
          <Card>
            {
              loading ?
                <Card.Body className="d-flex justify-content-center align-item-center">
                  <img src={Loading} width={100} height={100} />
                </Card.Body>
                
                :
                <Card.Body>
                  <h1>Log in Form</h1>
                  {error && <Alert className='warning'>{error}</Alert>}
                  <Form onSubmit={login} name='login_form'>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" value={email} required onChange={e => setEmail(e.target.value)} placeholder="example@email.com" />
                      <Form.Text className="text-muted">
                        We will never share your addres. Trust us!
                      </Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" value={password} required onChange={e => setPassword(e.target.value)} placeholder="enter password" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Text className="text-muted">
                        Do you have account? <a href="" onClick={handleClick}>SingUp</a>
                      </Form.Text>
                    </Form.Group>
                    {/* <Button className='mt-4' type='submit'>Submit</Button> */}
                    <div className="d-grid gap-2 mb-2 mt-5">
                      <Button
                        size='sm'
                        style={{ backgroundColor: "#fff", color: "black" }}
                        type="submit"
                        variant="secondary"
                      >
                        Login
                      </Button>
                    </div>
                  </Form>

                  <div className="d-grid gap-2 mb-2">
                    <Button
                      size='sm'
                      style={{ backgroundColor: "#fff", color: "black" }}
                      onClick={submitGoogle}
                      variant="secondary"
                    >
                      Register with Google
                    </Button>
                  </div>
                  <div className="d-grid gap-2 mb-2">
                    <Button
                      size='sm'
                      style={{ backgroundColor: "#8792a3" }}
                      onClick={SignInWithFacebook}
                      variant="secondary"
                    >
                      Register with Facebook
                    </Button>
                  </div>
                </Card.Body>
            }
          </Card>
        </Col>
      </Row>


    </Container>
  )
}

export default Login