import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../config/firebaseinit";

export const fetchDeliveryMans = createAsyncThunk('deliverymans/fetchAsyncDeliveryMans', async()=>{
    await onSnapshot(collection(db, "DeliveryMan"), snapshot => {
        let results = [];
        console.log('deli slice', snapshot)
        snapshot.forEach(doc => {
            results.push(doc.data())
        });
        return results;
    })
})

const initialState={
    deliveryMans:[],
}

const deliveryManSlice = createSlice({
    name:"deliveryMans",
    initialState,
    reducers:{
        addDeliveryMans:(state, {payload}) =>{
            state.deliveryMans = payload
        }
    },
    extraReducers:{
        [fetchDeliveryMans.pending]:()=>{
            console.log("Pending")
        },
        [fetchDeliveryMans.fulfilled]:(state, {payload})=>{
            console.log("fetched successfully");
            return {...state, deliveryMans:payload}
        },
        [fetchDeliveryMans.rejected]:()=>{
            console.log("Rejected!")
        }
    }
})

export const {addDeliveryMans} = deliveryManSlice.actions;
export const getAllDeliveryMans = (state) => state.deliveryMans;
export default deliveryManSlice.reducer;