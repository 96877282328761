import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { collection, onSnapshot, setDoc, doc } from "firebase/firestore";
import { db } from "../../../config/firebaseinit";
import { toast } from "react-toastify";

const colors = {
  orange: "#027afb",
  grey: "#a9a9a9",
};

const Rating = () => {
  const navigate = useNavigate();
  const { city, hotel_id } = useParams();
  const [authUser, setAuthUser] = useState('');
  const [currentValue, setCurrentValue] = useState(0);
  const [comment, setComment] = useState("");
  const [reviews, setReviews] = useState([]);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [dateTime, setDateTime] = useState('');

  const stars = Array(5).fill(0);
  useEffect(() => {
    getReviews();
    getDateTime()
  }, []);

  useEffect(() => {
    const data = localStorage.getItem('user')
    setAuthUser(JSON.parse(data))
  }, [])


  const getReviews = () => {
    // const reviewRef = collection(
    //   db,
    //   "Review",
    //   "Mandalay",
    //   "Hotels",
    //   "mercuremandalayhillresort",
    //   "data"
    // );

    const reviewRef = collection(
      db,
      "Review",
      city,
      "Hotels",
      hotel_id,
      "data"
    );

    onSnapshot(reviewRef, (snapshot) => {
      let results = [];
      snapshot.forEach((doc) => {
        results.push(doc.data());
      });
      setReviews(results);
    });
  };

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const getDateTime = () => {
    const date = new Date();
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    let time = new Intl.DateTimeFormat('en-US',
      {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric'
      }).format(date);
    let payload = day + "-" + month + "-" + year + " " + time

    setDateTime(payload);
  }

  const submitReview = (e) => {
    e.preventDefault();
    console.log(currentValue, comment);
    let reviewRef = doc(collection(db, 'reviews', city, 'hotels', hotel_id, 'data'));

    console.log("revrre", {
      'userid': reviewRef.id,
      'name': authUser?.displayName,
      'rating': currentValue,
      'review': comment,
      'date': dateTime
    })
    // setLoadingActive(true);
    try {
      setDoc(reviewRef, {
        'id': reviewRef.id,
        'name': authUser?.displayName,
        'rating': currentValue,
        'review': comment,
        'date': dateTime
      })
      // setLoadingActive(false);
      toast.success('Shop Created Successfully');
      // navigate('/hotel')

    } catch (err) {
      // setLoadingActive(false);
      console.log(err.message)
      toast.error('Try again! Cannot create the shop with ', err.message);
    }
  };

  console.log("reviews", reviews)

  return (
    <Container>
      <Row>
        <Col
          lg={4}
          className="mx-auto">
          <Row style={{ height: "40vh", overflowY: "scroll" }}>
            {reviews ? (
              reviews.map((result) => (
                <div className="comment" key={result?.userid}>
                  <div className="d-flex align-items-center">
                    <img
                      src={result.profile}
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "cover",
                      }}
                      className="rounded-circle"
                    />
                    <div className="ms-2 d-flex flex-column">
                      <span className="fw-bold">{result.name}</span>
                      <span className="">{result.rating}/5.0</span>
                    </div>

                  </div>
                  <p className="mt-1 ps-5">{result.review}</p>


                </div>
              ))
            ) : (
              <></>
            )}
          </Row>
          <div className="my-2 d-flex justify-content-center">
            {stars.map((_, index) => {
              return (
                <FaStar
                  key={index}
                  size={24}
                  onClick={() => handleClick(index + 1)}
                  onMouseOver={() => handleMouseOver(index + 1)}
                  onMouseLeave={handleMouseLeave}
                  color={
                    (hoverValue || currentValue) > index
                      ? colors.orange
                      : colors.grey
                  }
                  style={{
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                />
              );
            })}
          </div>
          <Form onSubmit={submitReview}>
            <Form.Control
              as="textarea"
              required
              cols={20}
              rows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Comment ..."
            />

            <Button
              className="my-2 float-end"
              type="submit"
              variant="primary">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Rating;