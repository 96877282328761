import React, { useState } from 'react'
import { Button, Col, Container, FloatingLabel, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
const NodeRSA = require('node-rsa');


const HotelBooking = () => {
    const [loadingActive, setLoadingActive] = useState(false);

    const [providerName, setProviderName] = useState("")
    const [methodName, setMethodName] = useState("")
    const [totalAmount, setTotalAmount] = useState("")
    const [orderId, setOrderId] = useState("")
    const [customerPhone, setCustomerPhone] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [items, setItems] = useState("")

    // console.log("p", providerName)

    const data = "{providerName: 'KBZ Pay',methodName: PWA,orderId: 'hnt-561092021001',customerPhone: '959796564600',customerName: 'Hsu Nyeint Thu', description:'Dinger Campaign Test',customerAddress:'Yangon, Myanmar' ,totalAmount: 100, items:'[{\"name\":\"Dinger\",\"amount\":\"100\",\"quantity\":\"1\"}]'}"
    const pubKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCOMoAG83sxlZzsmDY/oFh/G1B5wSYAD+wQo+bL5Lantc6R8fUV7LgmCm5SDZSnP6dlGanaVYVLUxhMErA/flVh47wDbGIAtjXhxyFYxyDx3dAAjF2HbE4kHIjx3z3BDomnEai8MDQ6yb8F6+5AyI98pQhDL8I8TuDXMx/SYy6vKQIDAQAB";
    const keys = new NodeRSA();
    keys.importKey(pubKey, 'pkcs8-public');
    keys.setOptions({ encryptionScheme: 'pkcs1' });
    const encrypt = keys.encrypt(data, 'base64');
    console.log("s", encrypt);

    const HttpCalled = () => {
        return `https://staging.dinger.asia/gateway/formCheckout?transactionNo=TRX2370920225729045722&formToken=5e7c6129-b918-42e9-aea1-8abc1d209e55&merchantOrderId=hnt-01092021001`;
    }
    // const httpCall = `https://staging.dinger.asia/gateway/formCheckout?transactionNo=TRX2370920225729045722&formToken=5e7c6129-b918-42e9-aea1-8abc1d209e55&merchantOrderId=hnt-01092021001`;


    const handleSubmit = (e) => {
        e.preventDefault();
        const httpCall = `https://staging.dinger.asia/gateway/formCheckout?transactionNo=TRX2370920225729045722&formToken=5e7c6129-b918-42e9-aea1-8abc1d209e55&merchantOrderId=hnt-01092021001`;

        console.log("first", httpCall())

    }
    return (
        <Container className="mt-3">
            <Col xs={12} lg="12">
                <Form onSubmit={handleSubmit} >
                    <Row >
                        <Col xs={12} md={6}>
                            <FloatingLabel className="mb-2" controlId="floatingprovidername" label="Provider Name">
                                <Form.Control type='text' value={providerName} placeholder='enter provider name' onChange={(e) => setProviderName(e.target.value)}></Form.Control>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} md={6}>
                            <FloatingLabel className="mb-2" controlId="floatingmethodname" label="Method Name">
                                <Form.Control type='text' value={methodName} placeholder='enter method name' onChange={(e) => setMethodName(e.target.value)}></Form.Control>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12} md={6}>
                            <FloatingLabel className="mb-2" controlId="floatingtotalAmount" label="Total Amount">
                                <Form.Control type='text' value={totalAmount} placeholder='enter Total Amount' onChange={(e) => setTotalAmount(e.target.value)}></Form.Control>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} md={6}>
                            <FloatingLabel className="mb-2" controlId="floatingorderId" label="OrderId">
                                <Form.Control type='text' value={orderId} placeholder='enter orderId' onChange={(e) => setOrderId(e.target.value)}></Form.Control>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12} md={6}>
                            <FloatingLabel className="mb-2" controlId="floatingcustomerphone" label="Customer Phone">
                                <Form.Control type='text' value={customerPhone} placeholder='enter customer phone' onChange={(e) => setCustomerPhone(e.target.value)}></Form.Control>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} md={6}>
                            <FloatingLabel className="mb-2" controlId="floatingcustomername" label="Customer Name">
                                <Form.Control type='text' value={customerName} placeholder='enter customer name' onChange={(e) => setCustomerName(e.target.value)}></Form.Control>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12} md={6}>
                            <FloatingLabel className="mb-2" controlId="floatingitem" label="Items">
                                <Form.Control type='text' value={items} placeholder='enter items' onChange={(e) => setItems(e.target.value)}></Form.Control>
                            </FloatingLabel>
                        </Col>

                    </Row>
                    <Button type='submit' variant='dark' disabled={loadingActive} className='my-3 w-100'> {loadingActive ? 'Saving...' : 'Submit'}</Button>

                </Form>
            </Col>
        </Container>
    )
}

export default HotelBooking