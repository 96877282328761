import React, { useEffect, useState } from 'react';
import homeStyle from './Home.module.scss'
import Banner1 from '../../assets/photos/banner1.png';
import Banner2 from '../../assets/photos/banner2.png';
import Apple from '../../assets/photos/apple.png';
import Mockup from '../../assets/photos/mockup.png';
import Playstore from '../../assets/photos/playstore.png';
import { Button, Carousel, Col, Container, Row } from 'react-bootstrap';
import { db } from '../../config/firebaseinit';
// import { collection, collectionGroup, doc, getDoc, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
const Home = () => {
  const [index, setIndex] = useState(0);
  const [hotels, setHotels] = useState([]);
//   useEffect(() => {
//     getData()
// }, [])
//   const getData = async () => {
//     const hotelRef = collection(db, "Yangon","Restaurants","RestaurantDetail");
    
//     await onSnapshot(hotelRef,snapshot => {
//         let results = [];
//         snapshot.forEach(doc => {
//             results.push(doc.data())
//         });
//         console.log(results)
//         setHotels(results)
//     })
// }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Container fluid>
      <Row className='justify-content-center'>
        {/* <Col lg='12' className='mt-lg-0 mt-5'>
          <Carousel activeIndex={index} onSelect={handleSelect} controls={true} indicators={false} slide={true}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner2}
                alt="First slide"
              />
              <Carousel.Caption>

              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner1}
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
        </Col> */}

        <Col lg='8' md='11' className='pt-5 mt-5 mb-5 pb-5'>
          <div className='d-lg-flex mx-auto justify-content-center align-items-center'>
            <div className='d-flex flex-column'>
              <span className='fs-2 mb-3 fw-bold'>Travel With <span className='fs-1 text-success fw-bolder'>Our Application</span></span>
              <p className='mb-3 fs-4'>Make your fantastic local trip with our application. Explore famous places, restaurants, hotels and festivals around Myanmar. Everything you need, we will serve as your trip partner. Get it on !!</p>
              <ul className='mb-3'>
                <li>
                  Order From Any Location
                </li>
                <li>
                  Follow Delivery Status
                </li>
                <li>
                  Get Important Notices
                </li>
              </ul>
              <div className='d-flex mt-3'>
                <a className='btn btn-dark' href='https://apps.apple.com/kh/app/flash-tour-myanmar-premium/id1605932713'>
                  <div>
                    <img src={Apple} width='20' />
                    <span className='ms-2'>App Store</span>
                  </div>

                </a>
                <a className='btn btn-dark ms-2' href='https://play.google.com/store/apps/details?id=com.lightidea.flashtourmyanmarpremium&hl=en&gl=US'>
                  <div>
                    <img src={Playstore} width='20' />
                    <span className='ms-2'>Play Store</span>
                  </div>
                </a>
              </div>

            </div>
            <div className='d-flex my-3 justify-content-center'>
              <img src={Mockup} width='200' />
            </div>
            <div>

            </div>
          </div>
        </Col>

        <Col lg='8'>
          <div className='my-auto text-center p-5'>
            <h1>About Us</h1>
            <p>Travel Smart With Flash Tour Myanmar</p>
            <Button className='btn btn-warning'>Learn More</Button>
          </div>
        </Col>
      </Row>

    </Container>

  )
}

export default Home