import { Routes, Route } from 'react-router-dom';
import './App.css';
import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
// Route Pages
import Home from './pages/home/Home';
import NavBar from './component/navbar/NavBar'
import SonixDelivery from './pages/sonixdelivery/SonixDelivery';
import CardHistory from './pages/sonixdelivery/history/CardHistory';
import QRScan from './pages/sonixdelivery/QRScan';
import WrongRead from './pages/sonixdelivery/WrongRead';
import Admin from './pages/admin/Admin';
import AdminLogin from './pages/admin/AdminLogin';
import ListOfDeliveryMan from './pages/deliveryman/list/ListOfDeliveryMan';
import CreateDeliveryMan from './pages/deliveryman/create/CreateDeliveryMan';
import DeliverManDetail from './pages/deliveryman/detail/DeliverManDetail';
import RouteGuard from './component/shares/RouteGuard';
import Footer from './component/footer/Footer';
import RestaurantList from './pages/restaurants/restaurantList/RestaurantList';
import HotelList from './pages/hotels/list/HotelList';
import HotelDetail from './pages/hotels/detail/HotelDetail';
import Login from './pages/hotels/login/Login';
import Singup from './pages/hotels/singup/Singup';
import HotelBooking from './pages/hotels/booking/HotelBooking';
import Rating from './pages/hotels/review/Rating';
import RestaurantDetail from './pages/restaurants/restaurantDetail/RestaurantDetail';
import RestaurantMenu from './pages/restaurants/restaurantMenu/RestaurantMenu';
// import { firebase } from './config/firebaseinit';

function App() {
  // const [user, setUser] = useState(null);

  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged(user => {
  //     setUser(user);
  //   })
  // }, [])


  return (
    <div className="App">
      <NavBar  />
      <Routes>

        <Route path='/login' element={<AdminLogin />} />
        <Route path="/admin" element={<RouteGuard><Admin /></RouteGuard>}>
          <Route path='delivery-man'>
            <Route path='all' element={<ListOfDeliveryMan />} />
            <Route path='create' element={<CreateDeliveryMan />} />
            <Route path='edit/:uid' element={<DeliverManDetail />} />
          </Route>
        </Route>

        <Route exact path='/' element={<Home />} />
        <Route exact path='/hotel-login' element={<Login />} />
        <Route exact path='/hotel-singup' element={<Singup />} />
        <Route exact path='/hotel' element={<HotelList />} />
        <Route exact path='/hotel/:city/:hotel_id' element={<HotelDetail />} />
        <Route exact path='/hotel/:city/:hotel_id/review' element={<Rating />} />

        <Route exact path='/hotel/:city/:hotel_id/booking' element={<HotelBooking />} />
        <Route path='/sonixDelivery' element={<SonixDelivery />} />
        <Route path='/QRScan' element={<QRScan />} />
        <Route path='/wrongread' element={<WrongRead />} />
        <Route path='/cardDetail/:cardId' element={<CardHistory />}></Route>

        <Route path='/restaurants' element={<RestaurantList />} />
        <Route exact path='/restaurant/:city/:restaurant_id' element={<RestaurantDetail />} />
        <Route exact path='/restaurant/:city/:restaurant_id/menu' element={<RestaurantMenu />} />


      </Routes>
      <br />
      <br />
      {/* <Footer></Footer> */}
    </div>
  );
}

export default App;
