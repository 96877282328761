import React, { useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router';
import { db, functions } from '../../../config/firebaseinit';
import { httpsCallable } from "firebase/functions";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loading from '../../../component/Loading';
import { doc, setDoc } from 'firebase/firestore';
import { FaChevronLeft } from 'react-icons/fa';

function CreateDeliveryMan() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('Lashio');
    const navigate = useNavigate();
    const [loadingActive, setLoadingActive] = useState(false);

    const submitDeliveryMan = async (e) => {
        e.preventDefault();
        setLoadingActive(true);
        const data = {
            'name': name,
            'email': email,
            'phone': phone,
            'password': password,
            'city': city
        }
        const registerUser = httpsCallable(functions, 'createDeliveryAccount');
        registerUser(data).then((result) => {
            console.log('user record', result.data.response);
            let deliManRef = doc(db, 'DeliveryMan', result.data.response.uid);
            setDoc(deliManRef, {
                'name': data.name,
                'email': data.email,
                'phone': data.phone,
                'city': data.city,
                'uid': result.data.response.uid,
                'available': "yes",
                'deliveryfree': "free",
                'deliveryporcess': "done",
                'locationlat': "0",
                'locationlog': "0",
                'photo': "default",
                'restaurantprocess': "done",
                'user': "Mg",
                'workonorderid': "no"
            }).then(() => {
                setLoadingActive(false);
                toast.success('Registered Successfully');
                navigate("/admin/delivery-man/all")
            })
        }).catch(err => {
            console.log(err.message)
            setLoadingActive(false)
            toast.error('Cannot create the user');
        })
    }
    return (
        <Container fluid className='pt-3'>
            <ToastContainer/>

            <Row>

                {
                    loadingActive ? <Loading /> : <></>
                }
                <Col lg={12}>
                    <div className='d-flex mb-2'>
                        <div className='btn p-0 fs-4 d-flex align-items-center' onClick={() => navigate(-1)}>
                            <FaChevronLeft />
                            <span>Create New Delivery Man</span>
                        </div>
                    </div>
                    {/* {error && <Alert className='warning'>{error}</Alert>} */}

                    <Form onSubmit={submitDeliveryMan}>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type='text' required value={name} placeholder='enter name' onChange={(e) => setName(e.target.value)}></Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type='email' required value={email} placeholder='enter email' onChange={(e) => setEmail(e.target.value)}></Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type='phone' required value={phone} placeholder='enter phone' onChange={(e) => setPhone(e.target.value)}></Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Select value={city} onChange={(e) => setCity(e.target.value)}>
                                <option value={'Lashio'}>Lashio</option>
                                <option value={'Mandalay'}>Mandalay</option>
                                <option value={'Yangon'}>Yangon</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='text' required value={password} placeholder='enter password' onChange={(e) => setPassword(e.target.value)}></Form.Control>
                        </Form.Group>

                        <Button type='submit' className='my-3 w-100'>Submit</Button>

                    </Form>
                </Col>

            </Row>

        </Container>
    )
}

export default CreateDeliveryMan