import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Table, Modal, Card } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore'
import { db, functions } from '../../../config/firebaseinit'
import { httpsCallable } from 'firebase/functions';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import "./ListOfDeliveryMan.scss";
import ReactPaginate from 'react-paginate';
import Loading from '../../../component/Loading';
function ListOfDeliveryMan() {
    const adminData = useSelector(state => state.adminData) || JSON.parse(localStorage.getItem('auth'));
    const [deleteId, setDeleteId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [loadingActive, setLoadingActive] = useState(false);

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        getData()
    }, [])


    const getData = async () => {
        await onSnapshot(collection(db, "DeliveryMan"), snapshot => {
            let results = [];
            snapshot.forEach(doc => {
                results.push(doc.data())
            });
            setData(results)
        })

    }

    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const currentPageData = data
        .slice(offset, offset + PER_PAGE)
        .map((listValue, index) => <tr key={listValue.uid}>
            <td>{index + 1}</td>
            <td>{listValue.name}</td>
            <td>{listValue.email}</td>
            <td>{listValue.phone}</td>
            <td className='text-nowrap text-end'>
                <Link to={`/admin/delivery-man/edit/${listValue.uid}`} className='btn btn-primary btn-sm'>Detail</Link>
                <Button className='btn btn-danger ms-1 btn-sm' onClick={() => deleteHanler(listValue.uid)}>Delete</Button>
            </td>
        </tr>);

    const pageCount = Math.ceil(data.length / PER_PAGE);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }
    const handleClose = () => {
        console.log(deleteId);
        setShowModal(false);
    }

    const deleteHanler = (uid) => {
        setDeleteId(uid);
        console.log('hadler id', deleteId)
        setShowModal(true);
    }

    const deliveryManDelete = async () => {
        setLoadingActive(true);
        console.log(deleteId)
        setShowModal(false)
        const registerUser = httpsCallable(functions, 'deleteDeliveryAccount');
        registerUser(deleteId).then(async (result) => {
            console.log('delete response:', result);
            await deleteDoc(doc(db, 'DeliveryMan', deleteId)).then(() => {
                setLoadingActive(false);
                toast.success("Account Deleted Successfully");
                getData();
            })
        }).catch(err => {
            setLoadingActive(false)
            console.log(err)
            toast.error(err.message)
        })
    }

    return (
        <Container fluid className='pt-3'>
            <ToastContainer/>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure to delete the account?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>No</Button>
                    <Button variant="primary" onClick={() => deliveryManDelete()}>Yes</Button>
                </Modal.Footer>
            </Modal>

            <Row>
                {
                    loadingActive || data.length == 0 ? 
                    <Loading/> : <></>
                }
                <Col >
                <span className='fs-3 my-3'>Delivery Men</span>

                    <Link to={'/admin/delivery-man/create'} className="btn btn-primary my-3 float-end">Add New</Link>
                </Col>

                <Col xs lg={12} className='table-box table-responsive mb-2' >
                   <Table striped bordered hover>

                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Control</th>

                            </tr>
                        </thead>

                        <tbody>
                            {currentPageData}
                        </tbody>
                    </Table>
                </Col>
                <Col className='border-top border-2 pt-2'>
                <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
                </Col>

                

            </Row>


        </Container>
    )
}

export default ListOfDeliveryMan