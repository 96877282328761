import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { useState } from 'react'
import { Container, Row, Col, Alert, Button, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../../config/firebaseinit'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../../assets/loading.gif';
import { db } from "../../../config/firebaseinit"
import { setHotelAuth } from "../../../features/hotelAuth/hotelAuthSlice"
import {
    GoogleAuthProvider, signInWithPopup, FacebookAuthProvider
} from 'firebase/auth'

import {
    query,
    getDocs,
    collection,
    where,
    doc,
    setDoc
} from "firebase/firestore";

const Singup = () => {

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const adminData = useSelector(state => state.adminData);
    // console.log('before', adminData);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const singUpSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (name.length == 0) {
            alert("name cannot be empty");
            return;
        } else {
            const { user } = await createUserWithEmailAndPassword(auth, email, password)
            // console.log(`User ${user.uid} created`)
            await updateProfile(user, {
                displayName: name,
            });

            if (user) {
                let userRef = doc(collection(db, 'User'));
                try {
                    setDoc(userRef, {
                        'id': userRef.id,
                        'email': user.email,
                        'name': name,
                    })

                    dispatch(setHotelAuth({ user, name: name }))

                    setName("");
                    setEmail("")
                    setPassword("")
                    toast.success('Shop Created Successfully');
                    navigate('/hotel');
                } catch (err) {
                    console.log(err.message)
                    toast.error('Try again! Cannot create the shop with ', err.message);
                }
            }
            // console.log("User profile updated")
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        navigate('/hotel-login')
    }

    const googleProvider = new GoogleAuthProvider();
    const SignInWithGoogle = async () => {
        try {
            // hello
            const res = await signInWithPopup(auth, googleProvider);
            const user = res.user;
            // console.log("uid", user.uid)
            const q = query(collection(db, "User"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            console.log("first", docs);
            if (docs.docs.length !== 0) {
                let userRef = doc(collection(db, 'User'));
                try {
                    setDoc(userRef, {
                        'id': userRef.id,
                        'email': user.email,
                        'name': user.displayName,
                    })
                    dispatch(setHotelAuth({ user, name: user.displayName }))
                    navigate('/hotel');
                } catch (err) {
                    console.log(err.message)
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    const facebookProvider = new FacebookAuthProvider();
    const SignInWithFacebook = async () => {
        try {
            signInWithPopup(auth, new FacebookAuthProvider())
            .then((result) => {
                const user = result.user;
                console.log(user);
            })
            .catch(function (error) {
                if (error.code === 'auth/account-exists-with-different-credential') {
                    console.log(FacebookAuthProvider.credentialFromError(error));
                    console.log(error.customData.email);
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Container>
            <Row>
                <Col className='col-5 mx-auto mt-5'>
                    <ToastContainer />
                    <Card>
                        {
                            loading ?
                                <Card.Body className="d-flex justify-content-center align-item-center">
                                    <img src={Loading} width={100} height={100} />
                                </Card.Body> :
                                <Card.Body>
                                    <h1>Sing Up Form</h1>
                                    {error && <Alert className='warning'>{error}</Alert>}
                                    <Form onSubmit={singUpSubmit} name='singup_form'>
                                        <Form.Group>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" value={name} required onChange={e => setName(e.target.value)} placeholder="enter name" />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" value={email} required onChange={e => setEmail(e.target.value)} placeholder="example@email.com" />
                                            <Form.Text className="text-muted">
                                                We will never share your addres. Trust us!
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" value={password} required onChange={e => setPassword(e.target.value)} placeholder="enter password" />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Text className="text-muted">
                                                Already have an account? <a href="" onClick={handleClick}>Login</a>
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group className="d-grid gap-2 mb-0">
                                            <Button size='sm' className='mt-4' type='submit'>Submit</Button>
                                        </Form.Group>
                                        {/* <div className="d-grid gap-2 mb-2">
                                            <Button
                                                size='sm'
                                                style={{ backgroundColor: "#fff", color: "black" }}
                                                onClick={SignInWithGoogle}
                                                variant="secondary"
                                            >
                                                Register with Google
                                            </Button>
                                        </div>
                                        <div className="d-grid gap-2 mb-2">
                                            <Button
                                                size='sm'
                                                style={{ backgroundColor: "#8792a3" }}
                                                onClick={SignInWithFacebook}
                                                variant="secondary"
                                            >
                                                Register with Facebook
                                            </Button>
                                        </div> */}
                                    </Form>
                                </Card.Body>
                        }
                    </Card>
                </Col>
            </Row>


        </Container>
    )
}

export default Singup