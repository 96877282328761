import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import { db } from '../../../config/firebaseinit';

function DeliverManDetail() {
    const [deliMan, setDeliMan] = useState('');
    const { uid } = useParams();
    const getDeliMan = async () => {  
        // const res = await axios.get(`http://localhost:8000/api/delivery-man/${uid}`);
        // const resData = res.data;
        // console.log('dli man', resData);
        // setDeliMan(resData)
        const deliveryManRef = doc(db, 'DeliveryMan',uid);
        let result =(await getDoc(deliveryManRef)).data();
        setDeliMan(result); 
    }

    useEffect(() => {
        getDeliMan()
    }, []);

    return (
        <Container>
            <Row>
                <Col>
                    <Card>

                        <Card.Body>
                            <Card.Title>
                                {deliMan.name}
                            </Card.Title>
                            <Card.Text>
                                {deliMan.uid}
                            </Card.Text>
                            <Card.Text>
                                {deliMan.email}
                            </Card.Text>
                            <Card.Text>
                                {deliMan.phone}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default DeliverManDetail