import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import logo from '../../assets/photos/flashTour.png'
import Facebook from '../../assets/photos/facebook.png'
import './Footer.module.scss'
function Footer() {
    return (
        <Container className='footer'>
            <Row>
                <Col className='d-flex justify-content-between'>
                    <img src={logo} width='200' height='30' />
                    <a href='https://www.facebook.com/flashtourmyanmar/'>
                        <img src={Facebook} width='50' />
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer