import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router';
import { getAdmin } from '../../features/adminAuth/adminAuthSlice';

const RouteGuard = ({children}) => {
    const adminData = useSelector(getAdmin);
    // console.log('route guard', adminData.admin, children)
    if(adminData.admin || JSON.parse(localStorage.getItem('auth'))) return children;
    else return <Navigate to="/login"/>
}

export default RouteGuard