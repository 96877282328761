
import React, { useState, useEffect } from 'react'
import { db } from '../../../config/firebaseinit';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import ReactPaginate from "react-paginate";
import {
  collection,
  onSnapshot,
} from "firebase/firestore";
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const cityList = [
  {
    name: "Bagan",
    value: 1
  },
  {
    name: "HpaAn",
    value: 2
  },
  {
    name: "Inle",
    value: 3
  },
  {
    name: "Kalaw",
    value: 4
  },
  {
    name: "Lashio",
    value: 5
  },
  {
    name: "Mandalay",
    value: 6
  },
  {
    name: "Monywa",
    value: 7
  },
  {
    name: "NayPyiTaw",
    value: 8
  },
  {
    name: "Pathein",
    value: 9
  },
  {
    name: "PyinOoLwin",
    value: 10
  },
  {
    name: "Taunggyi",
    value: 11
  },
  {
    name: "Yangon",
    value: 12
  },
]

const RestaurantList = () => {
  const [restaurantList, setRestaurantList] = useState([]);
  const [loadingActive, setLoadingActive] = useState(false);
  const [city, setCity] = useState('Bagan');
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [menuList, setMenuList] = useState([]);
  const [restaurantFilter, setRestaurantFilter] = useState(false)
  const [selectedType, setSelectedType] = useState('all');

  useEffect(() => {
    getRestaurant();
    getMenu()
  }, [city])

  const getRestaurant = async () => {
    setLoadingActive(true);
    setRestaurantList([]);
    const hotelRef = collection(db, city, "Restaurants", "RestaurantDetail")
    await onSnapshot(hotelRef, (snapshot) => {
      const result = [];
      snapshot.forEach((doc) => {
        result.push(doc.data());
      });
      setRestaurantList(result);
      setLoadingActive(false);
    });
  };

  const getMenu = async () => {
    setLoadingActive(true);
    setMenuList([]);
    const hotelRef = collection(db, city, "Restaurants", "Category")
    await onSnapshot(hotelRef, (snapshot) => {
      const result = [];
      snapshot.forEach((doc) => {
        result.push(doc.data());
      });
      setMenuList(result);
      setLoadingActive(false);
    });
  };


  const handleSelectedType = () => {
    setCurrentPage(0)
    setLoadingActive(true);
    setRestaurantFilter(true)
    setTimeout(() => {
      setLoadingActive(false);
    }, [1000])
  }

  useEffect(() => {
    if (selectedType == 'all') {
      setRestaurantFilter(false)
    }
  }, [selectedType])

  let PER_PAGE = 18;
  const offset = currentPage * PER_PAGE;


  let filterRestaurantsList = searchValue ? restaurantList.filter(restaurant => restaurant.name.toLowerCase().includes(searchValue.toLowerCase()))
    : restaurantList
  let currentPageDataList = filterRestaurantsList
    .slice(offset, offset + PER_PAGE);

  let filterRestaurants = searchValue ? restaurantList.filter(restaurant => restaurant.name.toLowerCase().includes(searchValue.toLowerCase()))
    : restaurantList.filter((rest) => rest.type === selectedType)
  const currentPageData = filterRestaurants
    .slice(offset, offset + PER_PAGE);


  // let currentPageData = filterRestaurants
  //   .slice(offset, offset + PER_PAGE)
  //   .map((listValue, index) => (
  //     <Col xs={6} md={4} lg={3} xl={2} key={index}>
  //       <Link to={`/restaurant/${city}/${listValue.data}`}>
  //         <Card className='shadow-none p-1 mb-4' style={{ height: '400px' }}>
  //           <Card.Img variant="top" className='img-thumbnail bg-none p-0' src={listValue.photo1} style={{ maxHeight: '150px' }} />
  //           <Card.Body className='py-0 px-1 mt-2'>
  //             <Card.Title style={{ 'height': '25%', fontSize: "18px" }}>{listValue.name}</Card.Title>
  //             <Card.Subtitle style={{ 'height': '15%', fontSize: "13px" }} className="my-3 text-muted">Address : {listValue.address}</Card.Subtitle>
  //             <Card.Subtitle style={{ 'height': '15%', fontSize: "13px" }} className="my-3 text-muted">Type : {listValue.type}</Card.Subtitle>
  //             <span className='text-primary mx-1 float-end'>
  //               Detail
  //             </span>
  //           </Card.Body>
  //         </Card>
  //       </Link>

  //     </Col>
  //   ));

  const pageCountList = Math.ceil(filterRestaurantsList.length / PER_PAGE);
  const pageCount = Math.ceil(filterRestaurants.length / PER_PAGE);

  // console.log("filterRestaurants", filterRestaurants.length)


  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
    setLoadingActive(true);
    setTimeout(() => {
      setLoadingActive(false);
    }, [2000])
  }

  return (
    <Container>
      <Row>
        <Col xs={6} md={4} lg={3} xl={2} >
          <div className='d-flex bg-white shadow-lg fixed-top align-items-center mx-auto search-bar'>
            <Form.Select aria-label="Default select example " style={{ borderRadius: '1.5rem' }} className='border border-secondary border-2' onChange={(e) => setCity(e.target.value)}>
              {
                cityList.map((city) => {
                  return (
                    <option value={city.name} defaultValue="Bagan" key={city.value} >{city.name}</option>
                  )
                })
              }
            </Form.Select>
            <Form.Control value={searchValue} placeholder='search ...' style={{ borderRadius: '1.5rem' }} className='border-0' onChange={e => setSearchValue(e.target.value)} />
          </div>
        </Col>
      </Row>


      <Row>
        <Col xs={12} md={12} lg={12} xl={12} >
          <div className='d-flex justify-content-between mt-5 align-items-center text-center'>
            {/* <Container > */}
            <Navbar bg="light" variant="light" style={{ cursor: "pointer", overflow: "scroll" }}>
              <Nav
                className="sub-nav"
                activeKey={selectedType}
                onClick={handleSelectedType}
                onSelect={(selectedKey) => setSelectedType(selectedKey)}
              >
                {
                  menuList.map((m) => {
                    return (
                      <Nav.Item key={m?.type} className="d-flex justify-content-between align-items-center text-center border rounded m-1 d-flex text-align-center w-100">
                        <Nav.Link href="" eventKey={m?.type} style={{ width: "200px" }}>{m?.name}</Nav.Link>
                      </Nav.Item>
                    )
                  })
                }
              </Nav>
            </Navbar>
            {/* </Container> */}
          </div>
        </Col>
      </Row>

      <Row className='mt-5 pt-2'>
        <>
          {loadingActive ? (
            <div className="text-center my-1 fw-bold">
              <span colSpan={8}>Loading ...</span>
            </div>
          ) : (
            <>
              {
                restaurantFilter ?
                  currentPageData.map((listValue, index) => (
                    <Col xs={6} md={4} lg={3} xl={2} key={index}>
                      <Link to={`/restaurant/${city}/${listValue.data}`}>
                        <Card className='shadow-none p-1 mb-4' style={{ height: '215px' }}>
                          <Card.Img variant="top" className='img-thumbnail bg-none p-0' src={listValue.photo1} style={{ maxHeight: '150px' }} />
                          <Card.Body className='py-0 px-1 mt-2'>
                            <Card.Title style={{ 'height': '25%', fontSize: "15px" }}>{listValue.name}</Card.Title>

                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ))
                  :
                  currentPageDataList.map((listValue, index) => (
                    <Col xs={6} md={4} lg={3} xl={2} key={index}>
                      <Link to={`/restaurant/${city}/${listValue.data}`}>
                        <Card className='shadow-none p-1 mb-4' style={{ height: '215px' }}>
                          <Card.Img variant="top" className='img-thumbnail bg-none p-0' src={listValue.photo1} style={{ maxHeight: '150px' }} />
                          <Card.Body className='py-0 px-1 mt-2'>
                            <Card.Title style={{ 'height': '25%', fontSize: "15px" }}>{listValue.name}</Card.Title>

                          </Card.Body>
                        </Card>
                      </Link>
                    </Col>
                  ))
              }
            </>
          )}
        </>

      </Row>

      <div id="react-paginate" style={{ display: `${loadingActive ? 'none' : 'block'}` }}>
        {
          (restaurantFilter && filterRestaurants.length !== 0) &&
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link "}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          
        }
        {
          (restaurantFilter && filterRestaurants.length == 0) &&
          <div className='d-flex justify-content-center align-items-center'>
            <h6 className='text-muted'>There are no Restaurants with this category!</h6>
          </div>
        }
        {
          (!restaurantFilter && filterRestaurantsList.length !== 0) &&
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={pageCountList}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link "}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        }


      </div>

    </Container>
  )
}


export default RestaurantList