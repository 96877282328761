import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Container, Card, Row, Col, Button } from 'react-bootstrap'
import { db } from '../../../config/firebaseinit'
import { collection, doc, getDoc, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import Style from "./CardHistory.module.scss"
const CardHistory = () => {
    const params = useParams();
    const [data, setData] = useState({});
    const [usage, setUsage] = useState([]);
    const id = params.cardId;
    const navigate = useNavigate();
    console.log('params', id);



    useEffect(() => {
        fetchData()
    }, [])



    const fetchData = async () => {
        try {
            const cardDoc = doc(collection(db, 'SonixDelivery', 'PrepaidCard', 'RegistCard'), id)
            await onSnapshot(cardDoc, snapshot => {
                setData(() => snapshot.data())
                console.log('card data', data)
            })

        } catch (err) {
            navigate('/wrongread')
            console.log(err.message)
        }
    }

    const formatDate = (dateString) => {

        const options = { year: "numeric", month: "long", day: "numeric", hour: '2-digit', minute: '2-digit', hour12: true }
        return dateString.toLocaleDateString(undefined, options)
    }

    const fetchUsage = async () => {
        try {
            const usageRef = query(collection(db, 'SonixDelivery', 'PrepaidCard', 'RegistCard', id, "UsageRecord"),orderBy('clicktime','desc'));
            await onSnapshot(usageRef, snapshot => {
                setUsage([]);
                let results = [];
                snapshot.forEach(doc => {
                    results.push(doc.data())
                });
                setUsage(results);

            })
        } catch (err) {
            console.log(err.message)
        }
    }

    let renderCardInfo = '';
    let renderUsage = '';

    const handleCheckUsage = () => {
        fetchUsage();
    }

    renderUsage = usage ? (
        usage.map((listValue) => {
            return (
                <Card className='mb-1' key={listValue.workid}>
                    <Card.Body>
                        <Card.Text>{formatDate(listValue.clicktime.toDate())}</Card.Text>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <span className='fs-3 fw-bold'>{listValue.waynumber} Way</span>

                            </div>
                            <div className='text-end d-flex flex-column'>
                                <span>{listValue.processtype}</span>
                                <span>{listValue.deliveryid}</span>

                            </div>

                        </div>

                        <Card.Text></Card.Text>
                    </Card.Body>
                </Card>
            );
        })
    ) : (
        <div>No Records</div>
    );

    renderCardInfo = data ? (
        <Card className={Style.card} >
            <Card.Body className={Style.card_body}>
                <Card.Title className='badge bg-secondary fs-6 rounded float-end'>{data.cardPackage}</Card.Title>
                <Card.Title as="h2">Available</Card.Title>
                <Card.Text as="h1" className={Style.txt_primary}>{data.waynumber} {parseInt(data.waynumber) > 1 ? 'ways' : 'way'}</Card.Text>
            </Card.Body>
            <Card.Footer className={Style.card_footer}>
                <div className='d-flex justify-content-between'>
                    <div className='w-50'>
                        <p className='mb-0 h6'>Holder Name</p>
                        <p className='mb-0'>{data.name}</p>
                    </div>
                    <div className='w-50'>
                        <p className='mb-0 text-end h6'>Ph No</p>
                        <p className='mb-0 text-end'>{data.phone}</p>
                    </div>
                </div>
                <hr></hr>
                <div className='d-flex justify-content-between'>
                    <div className='w-50'>
                        <p className='mb-0 h6'>Email</p>
                        <p className='mb-0'>{data.email}</p>
                    </div>
                    <div className='w-50'>
                        <p className='mb-0 text-end h6'>Address</p>
                        <p className='mb-0 text-end'>{data.address}</p>
                    </div>
                </div>

            </Card.Footer>
        </Card >

    ) : (
        <Card className={Style.card} >
            <Card.Body className={Style.card_body}>
                <Card.Title as="h2">The card is not existed.</Card.Title>
            </Card.Body>
            <Card.Footer className={Style.card_footer}>
            </Card.Footer>
        </Card >

    )

    return (
        <Container className={Style.container_box}>
            <Row className=' mt-3'>
                <Col xs='12' lg='6' md='6'>
                    {renderCardInfo}
                </Col>
                <Col xs='12' lg='6' md='6' className={Style.usage_box}>
                    <Button className={Style.btn_primary} onClick={() => handleCheckUsage()}>Check Usages</Button>
                    <div>{renderUsage}</div>
                </Col>
            </Row>
        </Container>
    )
}

export default CardHistory