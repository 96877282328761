import React, { useState, useEffect } from 'react'
import { db } from '../../../config/firebaseinit';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ReactPaginate from "react-paginate";
import './hotelList.scss'
import {
    collection,
    onSnapshot,
} from "firebase/firestore";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const cityList = [
    {
        name: "Bagan",
        value: 1
    },
    {
        name: "HpaAn",
        value: 2
    },
    {
        name: "Inle",
        value: 3
    },
    {
        name: "Kalaw",
        value: 4
    },
    {
        name: "Lashio",
        value: 5
    },
    {
        name: "Mandalay",
        value: 6
    },
    {
        name: "Monywa",
        value: 7
    },
    {
        name: "NayPyiTaw",
        value: 8
    },
    {
        name: "Pathein",
        value: 9
    },
    {
        name: "PyinOoLwin",
        value: 10
    },
    {
        name: "Taunggyi",
        value: 11
    },
    {
        name: "Yangon",
        value: 12
    },
]
const HotelList = () => {
    const [hotelList, setHotelList] = useState([]);
    const [loadingActive, setLoadingActive] = useState(false);
    const [city, setCity] = useState('Bagan');
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        getHotel();
    }, [city])

    const getHotel = async () => {
        setHotelList([]);
        setLoadingActive(true);
        const hotelRef = collection(db, city, "Hotel", "HotelDetail")

        await onSnapshot(hotelRef, (snapshot) => {
            const result = [];
            snapshot.forEach((doc) => {
                result.push(doc.data());
            });
            setHotelList(result);
            setLoadingActive(false);
        });
    };

    // console.log("first", hotelList);
    let filterHotels = searchValue ? hotelList.filter(hotel => hotel.name.toLowerCase().includes(searchValue.toLowerCase())) : hotelList

    const PER_PAGE = 18;
    const offset = currentPage * PER_PAGE;
    const currentPageData = filterHotels
        .slice(offset, offset + PER_PAGE)
        .map((listValue, index) => (
            <Col xs={6} md={4} lg={3} xl={2} key={index}>
                <Link to={`/hotel/${city}/${listValue.data}`}>

                    <Card className='shadow-none p-1 mb-4' style={{ height: '215px' }}>
                        <Card.Img variant="top" className='img-thumbnail bg-none p-0' src={listValue.photo1} style={{ maxHeight: '150px' }} />
                        <Card.Body className='py-0 px-1 mt-2'>
                            <Card.Title style={{ 'height': '20%', fontSize: "15px" }}>{listValue.name}</Card.Title>
                            {/* <Card.Subtitle style={{ 'height': '15%', fontSize: "13px" }} className="my-3 text-muted">Price : {listValue.price}</Card.Subtitle>
                            <Card.Text className='text-wrap' style={{ 'height': '20%', fontSize: '12px' }}>
                                {listValue?.location?.length > 50 ? listValue?.location?.slice(0, 50) + '.....' : listValue?.location}
                            </Card.Text>
                            <span className='text-primary mx-1 float-end'>
                                Detail
                            </span> */}
                        </Card.Body>
                    </Card>
                </Link>

            </Col>
        ));

    const pageCount = Math.ceil(hotelList.length / PER_PAGE);

    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
        setLoadingActive(true);
        setTimeout(() => {
            setLoadingActive(false);
        }, [2000])
    }


    return (
        <Container>
            <Row>
                <div className='d-flex bg-white shadow-lg fixed-top align-items-center mx-auto search-bar'>
                    <Form.Select aria-label="Default select example " style={{ borderRadius: '1.5rem' }} className='border border-secondary border-2' onChange={(e) => setCity(e.target.value)}>
                        {
                            cityList.map((city) => {
                                return (
                                    <option value={city.name} defaultValue="Bagan" key={city.value} >{city.name}</option>
                                )
                            })
                        }
                    </Form.Select>
                    <Form.Control value={searchValue} placeholder='search ...' style={{ borderRadius: '1.5rem' }} className='border-0' onChange={e => setSearchValue(e.target.value)} />
                </div>
            </Row>

            <Row className='mt-5 pt-2'>
                <>
                    {loadingActive ? (
                        <div className="text-center my-1 fw-bold">
                            <span colSpan={8}>Loading ...</span>
                        </div>
                    ) : (
                        currentPageData
                    )}
                </>

            </Row>

            <div id="react-paginate" style={{ display: `${loadingActive ? 'none' : 'block'}` }}>
                <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link "}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            </div>
        </Container>
    )
}

export default HotelList