import {
  doc,
  getDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Col, Container, Row, Modal } from "react-bootstrap";
// import Modal from 'react-bootstrap/Modal';
import { Link, useParams } from "react-router-dom";
import { db } from "../../../config/firebaseinit";
// import { useNavigate } from 'react-router-dom'
import ReactPaginate from "react-paginate";

function RestaurantDetail() {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [restaurant, setRestaurant] = useState("");
  const { city, restaurant_id } = useParams();
  const [facility, setFacility] = useState("");
  const [index, setIndex] = useState(0);
  const [loadingActive, setLoadingActive] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  // const [selectedType, setSelectedType] = useState('all');
  const [show, setShow] = useState(false);

  useEffect(() => {
    getRestaurant();
    getMenu();
  }, [city]);


  const getRestaurant = async () => {
    setLoadingActive(true)
    const hotelRef = doc(db, city, "Restaurants", "RestaurantDetail", restaurant_id);
    try {
      let result = (await getDoc(hotelRef));
      setRestaurant(result.data());
      setLoadingActive(false)

    } catch (err) {
      console.log(err.message);
      setLoadingActive(false)

    }
  };

  const getMenu = async () => {
    setMenuList([]);
    const hotelRef = collection(db, city, "Restaurants", "RestaurantDetail", restaurant_id, "Menu")
    await onSnapshot(hotelRef, (snapshot) => {
      const result = [];
      snapshot.forEach((doc) => {
        // console.log("dd", doc.data())
        result.push(doc.data());
      });
      setMenuList(result);
      setLoadingActive(false);
    });
  };


  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  let filterRestaurants = searchValue ? menuList.filter(restaurant => restaurant.name.toLowerCase().includes(searchValue.toLowerCase())) : menuList

  const PER_PAGE = 12;
  const offset = currentPage * PER_PAGE;
  const currentPageData = filterRestaurants
    .slice(offset, offset + PER_PAGE)
    .map((listValue, index) => (
      <Col xs={6} md={4} lg={3} xl={2} key={index}>
        <Link to="">
          <Card className='shadow-none p-1 mb-4' style={{ height: '300px' }}>
            <Card.Img variant="top" className='img-thumbnail bg-none p-0' src={listValue?.url} style={{ maxHeight: '150px' }} />
            <Card.Body className='py-0 px-1 mt-2'>
              <Card.Title className="mb-0" style={{ 'height': '25%', fontSize: "18px" }}>{listValue?.name}</Card.Title>
              <Card.Text className="mt-2 text-start mb-0">
                <span>Available : </span> <span className="text-muted">{listValue?.available}</span>
              </Card.Text>
              <Card.Text className="mt-0 text-start mb-0">
                <span>Price : </span> <span className="text-muted">{Number(listValue?.price).toLocaleString()} Ks</span>
              </Card.Text>
              <Card.Text className="mt-0 text-start mb-0">
                <span>Type : </span> <span className="text-muted">{listValue?.type}</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>

      </Col>
    ));

  const pageCount = Math.ceil(menuList.length / PER_PAGE);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container>

      {
        loadingActive ?
          <div className="text-center my-1 fw-bold">
            <span colSpan={8}>Loading ...</span>
          </div>
          :
          <>
            <Row>
              <Col
                xs={12}
                lg={12}
                className="my-3">

                <div className="d-flex justify-content-center align-items-center">
                  <p className="text-center fs-4">{restaurant.name}
                  </p>
                  <button onClick={handleShow} style={{ marginLeft: "10px", marginBottom: "15px" }} className="border-0 bg-primary text-white rounded-pill text-center "><i className="fa fa-info m-2 text-center fs-6"></i></button>
                </div>
              </Col>
            </Row>

            <Row>
              <Row className='mt-2 pt-2'>
                <>
                  {loadingActive ? (
                    <div className="text-center my-1 fw-bold">
                      <span colSpan={8}>Loading ...</span>
                    </div>
                  ) : (
                    currentPageData
                  )}
                </>
              </Row>
              <>
                {
                  loadingActive ? (
                    <></>
                  ) : (

                    menuList.length > 0 && (
                      <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link "}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    )
                  )
                }
              </>
            </Row >
            <>

              <Modal show={show} onHide={handleClose} style={{ marginTop: "40px" }}>
                <Modal.Header closeButton>
                  <Modal.Title>{restaurant?.name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Row className="d-flex justify-content-center align-items-center ">
                    <Col
                      lg={10}
                      xs={12}
                      md={7}>

                      <Carousel
                        className="mb-5 "
                        activeIndex={index}
                        style={{ maxHeight: "200px" }}
                        onSelect={handleSelect}>
                        <Carousel.Item style={{ maxHeight: "200px" }}>
                          <img

                            className="d-block w-100"
                            src={restaurant.photo1}
                            alt="First slide"
                          />
                        </Carousel.Item>
                        <Carousel.Item style={{ maxHeight: "200px" }}>
                          <img
                            className="d-block w-100"
                            src={restaurant.photo2}
                            alt="First slide"
                          />
                        </Carousel.Item>
                        <Carousel.Item style={{ maxHeight: "200px" }}>
                          <img
                            className="d-block w-100"
                            src={restaurant.photo3}
                            alt="First slide"
                          />
                        </Carousel.Item>

                      </Carousel>

                    </Col>
                  </Row>
                  <Col
                    xs={12}
                    md={12}>
                    {restaurant ? (
                      <Card>
                        <Card.Text className="fs-5">Facilities</Card.Text>
                        <div className="d-flex">
                          {restaurant.park ? (
                            <div className="icon-badge">
                              <i className="fa fa-taxi "></i>
                            </div>
                          ) : (
                            <></>
                          )}
                          {restaurant.book ? (
                            <div className="icon-badge">
                              <i className="fa fa-book"></i>
                            </div>
                          ) : (
                            <></>
                          )}

                          {restaurant.promotion ? (
                            <div className="icon-badge">
                              <i className="fa fa-percent"></i>
                            </div>
                          ) : (
                            <></>
                          )}
                          {restaurant.menu ? (
                            <div className="icon-badge">
                              <i className="fa-sharp fa fa-bars"></i>
                            </div>
                          ) : (
                            <></>
                          )}
                          {/* {facility.ticketservice ? (
                            <div className="icon-badge">
                              <i className="fa fa-tags "></i>
                            </div>
                          ) : (
                            <></>
                          )}
                          {facility.fit ? (
                            <div className="icon-badge">
                              <img
                                src={require("../../../assets/exercise.png")}
                                width="30"
                                height="30"
                              />
                            </div>
                          ) : (
                            <></>
                          )} */}
                          {/* {facility.wifi ? (
                            <div className="icon-badge">
                              <img
                                src={require("../../../assets/bar-counter.png")}
                                width="30"
                                height="30"
                              />
                            </div>
                          ) : (
                            <></>
                          )} */}
                        </div>
                      </Card>
                    ) : (
                      <></>
                    )}
                    <Button
                      variant="primary"
                      className="w-100 rounded-pill my-3"
                    >
                    </Button>

                    <Card>
                      <Card.Text className="fs-5">Contact</Card.Text>
                      <table className="table border-none">
                        <tbody>
                          <tr>
                            <td>Type</td>
                            <td>{restaurant.type}</td>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>{restaurant.phone}</td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>{restaurant.address}</td>
                          </tr>

                        </tbody>
                      </table>
                    </Card>
                    <div className="floating-btn rounded-circle shadow-lg">
                      <Button
                        variant="success"
                        className="shadow-lg d-flex text-center justify-content-center align-items-center">
                        <i className="fa fa-directions fa-2x"></i>
                      </Button>
                    </div>
                  </Col>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>

                </Modal.Footer>
              </Modal>
            </>
          </>
      }
    </Container >
  );
}

export default RestaurantDetail;
