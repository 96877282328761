import React from 'react'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Link, NavLink, Outlet, useNavigate } from 'react-router-dom'
import { setAdminLogout } from '../../features/adminAuth/adminAuthSlice'
import './Admin.css';
import { FaSignOutAlt } from 'react-icons/fa';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import Sidebar from '../../component/sidebar/Sidebar'
const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(setAdminLogout());
    navigate('/login');

  }
  return (
    <Container fluid className='main-box p-0' >
      <div className='d-flex'>
        <div className='sidebar'>
          <CDBSidebar textColor="#fff" backgroundColor="#16569B">
            <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
              <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
                Sonix Admin
              </a>
            </CDBSidebarHeader>

            <CDBSidebarContent className="sidebar-content">
              <CDBSidebarMenu>
                <NavLink  to="/admin" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>
                <NavLink to="/admin/delivery-man/all" >
                  <CDBSidebarMenuItem icon="table">Delivery Man</CDBSidebarMenuItem>
                </NavLink>
                <NavLink to="/admin" >
                  <CDBSidebarMenuItem icon="user">Admin Profile</CDBSidebarMenuItem>
                </NavLink>
                <NavLink to="/admin" >
                  <CDBSidebarMenuItem icon="chart-line">Analytics</CDBSidebarMenuItem>
                </NavLink>
              </CDBSidebarMenu>
            </CDBSidebarContent>

            <CDBSidebarFooter style={{ textAlign: 'center' }}>
              <div
                style={{
                  padding: '20px 5px',
                }}
              >
                <CDBSidebarMenuItem className="btn text-light" onClick={logout}><FaSignOutAlt/>Logout</CDBSidebarMenuItem>
              </div>
            </CDBSidebarFooter>
          </CDBSidebar>
        </div>
        <div className="sidebar-body">
          <Outlet />
        </div>
      </div>


    </Container>
  )
}

export default Admin