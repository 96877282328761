import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setHotelAuthLogout } from '../../features/hotelAuth/hotelAuthSlice'
// import navStyle from './Navbar.module.scss'
import Container from 'react-bootstrap/Container'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
// import image
import logoImg from '../../assets/photos/flashTour.png'
import NavStyle from './Navbar.module.scss'


const NavBar = () => {
  const [user, setUser] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(setHotelAuthLogout());
    setUser('')
    navigate('/login');
  }

  useEffect(() => {
    const getHotel = localStorage.getItem("user");
    setUser(getHotel)
  }, [localStorage.getItem("user")])


  return (
    <Navbar sticky="top" bg='light' expand='lg' style={{ zIndex: '10000' }}>
      <Container>
        <Link to='/'>
          <Navbar.Brand>
            <img src={logoImg} alt="" />
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" className={NavStyle.toggle} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Link className='nav-link' to='/'>Home</Link>
            <Link className='nav-link' to='/hotel'>Hotels</Link>
            <Link className='nav-link' to='/restaurants'>Restaurants</Link>
            {
              user ? <Link className='nav-link' to='' onClick={logout}>Logout</Link> :
                <Link className='nav-link' to='/hotel-login'>Login</Link>
            }
            <NavDropdown title="Sonix Delivery" id="basic-nav-dropdown">
              <Link className='nav-link' to='/sonixDelivery'>Premium Card</Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar