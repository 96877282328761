import React from 'react'
import { Card } from 'react-bootstrap';
import LoadingImg from "../assets/loading.gif";
import './Loading.css';
const Loading = () => {
    return (
        <div className='modal-bg'>
            <Card className='modal-box'>
                <img src={LoadingImg} width="200" height="200" />
            </Card>
        </div>

    )
}

export default Loading